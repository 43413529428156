import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";


export type ZexyRoadmapPartProps = {

};

type ZexyRoadmapPartState = {
    here: boolean;
};

export class ZexyRoadmapPart extends React.Component<ZexyRoadmapPartProps, ZexyRoadmapPartState> {
    state: ZexyRoadmapPartState = {
        here: true
    };

    // #region React Events
    componentDidMount = async () => {
        // setup connection

        // await this.setState((prevState) => {
        //   return { here: true };
        // });
    };

    componentDidUpdate = async (prevProps: ZexyRoadmapPartProps, prevState: ZexyRoadmapPartState) => {
        // execute
    };

    componentWillUnmount = async () => {
        // destroy connection
    };
    // #endregion

    // #region Handlers
    // #endregion

    // #region State Setters
    // #endregion

    // #region Functions
    // #endregion

    render() {
        return (
            <React.Fragment>
                <section id="roadmap">
                    <div className="zexynomics-part" style={{ height: "fit-content" }}>
                        <img className="zexy-roadmap-title-image" style={{ width: "1025px", marginLeft: "150px", marginTop: "80px" }} src="images/zexy-roadmap-title.png" />
                        <div className="roadmap-container">
                            <img className="zexy-food-icon" src="images/zexy-food-icon-1.png" />
                            <img
                                style={{
                                    left: " 90%",
                                    marginTop: "-55px"
                                }}
                                className="zexy-food-icon" src="images/zexy-food-icon-2.png"
                            />
                            <img
                                style={{
                                    left: "50%",
                                    marginTop: "3%"
                                }}
                                className="zexy-food-icon" src="images/zexy-food-icon-3.png" />
                            <img
                                style={{
                                    left: " 30%",
                                    marginTop: "7%"
                                }}
                                className="zexy-food-icon" src="images/zexy-food-icon-4.png"
                            />
                            <img
                                style={{
                                    marginTop: "20%",
                                    left: "11%"
                                }}
                                className="zexy-food-icon" src="images/zexy-food-icon-5.png" />
                            <img style={{
                                left: "5%",
                                marginTop: "62%"
                            }} className="zexy-food-icon" src="images/zexy-food-icon-6.png" />
                            <img
                                style={{
                                    left: " 30%",
                                    marginTop: "60%"
                                }}
                                className="zexy-food-icon" src="images/zexy-food-icon-7.png"
                            />
                            <img
                                style={{
                                    left: "35%",
                                    marginTop: "42%"
                                }}
                                className="zexy-food-icon" src="images/zexy-food-icon-8.png" />
                            <img style={{
                                left: "55%",
                                marginTop: "50%"
                            }}
                                className="zexy-food-icon" src="images/zexy-food-icon-9.png" />
                            <img style={{
                                left: "87%",
                                marginTop: "59%"
                            }} className="zexy-food-icon" src="images/zexy-food-icon-10.png" />
                            <div className="first-and-second-phase">
                                <div className="first-phase-wrapper">
                                    <div className="first-phase" style={{ textAlign: "center", padding: "0px 10px" }}>
                                        <img style={{ width: "80%" }} src="images/zexy-first-step-roadmap-title.png" />
                                        <h4 style={{ color: "white" }}>List $ZEXY on DEXes</h4>

                                        <h4 style={{ color: "white" }}>Massive Marketing Campaign</h4>

                                        <h4 style={{ color: "white" }}> CMC & CoinGecko</h4>
                                    </div>
                                </div>
                                <div className="roadmap-arrow">
                                    <img style={{ position: "absolute" }} src="images/zexy-roadmap-arrow.png" />
                                </div>
                                <div className="fat-girl-image">
                                    <img style={{ position: "absolute", zIndex: "3", width: "542px" }} src="images/zexy-fat-girl-image.png" />
                                </div>
                                <div className="first-phase-wrapper">
                                    <div className="second-phase" style={{ textAlign: "center", padding: "0px 10px" }}>
                                        <img style={{ width: "80%" }} src="images/zexy-second-step-roadmap-title.png" />
                                        <h4 style={{ color: "white" }}>CEX listings</h4>

                                        <h4 style={{ color: "white" }}>Launch of EATtheMEME Game</h4>

                                        <h4 style={{ color: "white" }}>Bonus campaigns for $ZEXY holders</h4>

                                        <h4 style={{ color: "white" }}>NFT Collection</h4>

                                    </div>
                                </div>
                            </div>

                            {/* <div className="roadmap-arrow">
                                <img style={{position: "absolute"}}src="images/zexy-roadmap-arrow-2.png"/>
                            </div> */}
                            <div className="first-and-second-phase">
                                <div className="first-phase-wrapper">
                                    <div className="third-phase" style={{ textAlign: "center", padding: "0px 10px" }}>
                                        <img style={{ width: "80%" }} src="images/zexy-third-step-roadmap-title.png" />
                                        <h4 style={{ color: "white" }}>Binance and Bybit</h4>

                                        <h4 style={{ color: "white" }}>Listings</h4>

                                        <h4 style={{ color: "white" }}>Brand Partnerships</h4>
                                        <h4 style={{ color: "white" }}>Distribute Millions of $ZEXY Tokens</h4>
                                        <h4 style={{ color: "white" }}>Special $ZEXY Prizes For Holders</h4>
                                    </div>
                                </div>
                                <div className="roadmap-arrow second-roadmap-arrow">
                                    <img style={{ position: "absolute" }} src="images/zexy-roadmap-arrow-2.png" />
                                </div>
                                <div className="roadmap-arrow third-roadmap-arrow">
                                    <img style={{ position: "absolute" }} src="images/zexy-roadmap-arrow.png" />
                                </div>
                                <div className="first-phase-wrapper">
                                    <div className="last-phase" style={{ textAlign: "center", marginTop: "90px" }}>
                                        <img style={{ width: "80%" }} src="images/zexy-last-step-roadmap-title.png" />
                                        <h4 style={{ color: "white" }}>Collaboration with SpaceX</h4>

                                        <h4 style={{ color: "white" }}>The Biggest World Influencers</h4>

                                        <h4 style={{ color: "white" }}>Making $ZEXY global
                                            social currency</h4>
                                        <h4 style={{ color: "white" }}>Empower $ZEXY holders above the elites</h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-roadmap-container">
                            <div className="mobile-roadmap" style={{ width: "75%", margin: "auto" }}>
                                <div className="first-phase-wrapper">
                                    <div className="roadmap-phase first-phase" style={{ textAlign: "center", padding: "0px 10px" }}>
                                        <img style={{ width: "80%" }} src="images/zexy-first-step-roadmap-title.png" />
                                        <h4 style={{ color: "white" }}>List $ZEXY on DEXes</h4>

                                        <h4 style={{ color: "white" }}>Massive Marketing Campaign</h4>

                                        <h4 style={{ color: "white" }}> CMC & CoinGecko</h4>
                                    </div>
                                </div>
                                <div style={{width: "90%", margin: "auto", textAlign: "center"}}>
                                    <img src="images/zexy-roadmap-mobile-arrow.png" />
                                </div>
                                <div className="roadmap-phase second-phase" style={{ textAlign: "center", padding: "0px 10px" }}>
                                    <img style={{ width: "80%" }} src="images/zexy-second-step-roadmap-title.png" />
                                    <h4 style={{ color: "white" }}>CEX listings</h4>

                                    <h4 style={{ color: "white" }}>Launch of EATtheMEME Game</h4>

                                    <h4 style={{ color: "white" }}>Bonus campaigns for $ZEXY holders</h4>

                                    <h4 style={{ color: "white" }}>NFT Collection</h4>

                                </div>
                                <div style={{width: "90%", margin: "auto", textAlign: "center"}}>
                                    <img src="images/zexy-roadmap-mobile-arrow.png" />
                                </div>
                                <div className="roadmap-phase third-phase" style={{ textAlign: "center", padding: "0px 10px" }}>
                                    <img style={{ width: "80%" }} src="images/zexy-third-step-roadmap-title.png" />
                                    <h4 style={{ color: "white" }}>Binance and Bybit</h4>

                                    <h4 style={{ color: "white" }}>Listings</h4>

                                    <h4 style={{ color: "white" }}>Brand Partnerships</h4>
                                    <h4 style={{ color: "white" }}>Distribute Millions of $ZEXY Tokens</h4>
                                    <h4 style={{ color: "white" }}>Special $ZEXY Prizes For Holders</h4>
                                </div>
                                <div style={{width: "90%", margin: "auto", textAlign: "center"}}>
                                    <img src="images/zexy-roadmap-mobile-arrow.png" />
                                </div>
                                <div className="roadmap-phase last-phase" style={{ textAlign: "center" }}>
                                    <img style={{ width: "80%" }} src="images/zexy-last-step-roadmap-title.png" />
                                    <h4 style={{ color: "white" }}>Collaboration with SpaceX</h4>

                                    <h4 style={{ color: "white" }}>The Biggest World Influencers</h4>

                                    <h4 style={{ color: "white" }}>Making $ZEXY global
                                        social currency</h4>
                                    <h4 style={{ color: "white" }}>Empower $ZEXY holders above the elites</h4>

                                </div>
                            </div>
                        </div>

                    </div>

                </section>
            </React.Fragment>
        );
    }
}
import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";

export type MeetTheTeamPartProps = {};

type MeetTheTeamPartState = {
  here: boolean;
};

export class MeetTheTeamPart extends React.Component<MeetTheTeamPartProps, MeetTheTeamPartState> {
  state: MeetTheTeamPartState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: MeetTheTeamPartProps, prevState: MeetTheTeamPartState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section id="meetTheTeam">
          <div
            className="meet-the-team-div"
            style={{
              height: "max-content",
              display: "flex",
              width: "75%",
              margin: "auto",
              marginTop: "104px",
              alignContent: "flex-start",
              alignItems: "flex-start",
              justifyContent: "space-around"
            }}
          >
            <div className="team-text-description" style={{ width: "45%" }}>
              <div
                className="zexy-text-box"
                style={{
                  color: "white",
                  fontSize: "34px",
                  fontFamily: "sans-serif",
                  // width: "75%",
                  margin: "auto",
                  border: "1px solid transparent",
                  backgroundColor: "#ffffff36",
                  borderRadius: "50px",
                  padding: "30px 75px",
                  fontWeight: "500"
                }}
              >
                At the heart of <strong style={{ color: "#FACC08" }}>$ZEXY</strong> is the exceptional <strong style={{ color: "#FACC08" }}>ZoneX Team</strong>, renowned for their
                <strong style={{ color: "#FACC08" }}> groundbreaking success</strong> in selling out an entire
                <strong style={{ color: "#FACC08" }}> NFT collection</strong>. Get to know the <strong style={{ color: "#FACC08" }}> vibrant personalities </strong>
                driving this <strong style={{ color: "#FACC08" }}> meme-coin revolution</strong>:
              </div>

              <div>
                <div
                  id="meet-David"
                  style={{
                    fontSize: "50px",
                    color: "white",
                    border: "2px solid white",
                    borderRadius: "35px",
                    textAlign: "center",
                    marginTop: "50px",
                    padding: "20px",
                    marginBottom: "15px",
                    fontFamily: "sans-serif",
                    fontWeight: "600"
                  }}
                >
                  <strong style={{ color: "#FACC08" }}>David</strong> the Chitauri
                  <div className="mobile-socials-team-wrapper" style={{ width: "127px", display: "flex", margin: "auto", justifyContent: "center" }}>
                    <a href="https://www.linkedin.com/in/david-bonev-79194b168/?originalSubdomain=bg">
                      <img className="mobile-team-icons" style={{ width: "60px" }} src="images/linkedin-yellow-image.png" />
                    </a>
                    {/* <a href="https://x.com/davidrocka?lang=bg">
                      <img className="mobile-team-icons" style={{ width: "60px" }} src="images/x-yellow-image.png" />
                    </a> */}
                  </div>
                </div>
                {/* <img src="images/linkedin-yellow-image.png"/> */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ width: "49%" }}>
                  <div
                    style={{
                      fontSize: "27px",
                      color: "white",
                      border: "2px solid white",
                      borderRadius: "30px",
                      textAlign: "center",
                      // marginTop: "50px",
                      padding: "20px",
                      // marginBottom: "15px",
                      fontFamily: "sans-serif",
                      fontWeight: "600",
                      margin: "5px 0px",
                      marginBottom: "15px",
                    }}
                  >
                    <strong style={{ color: "#FACC08" }}>Martin</strong> the Explorer
                    <div
                      className="mobile-socials-team-wrapper"
                      style={{
                        width: "118px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "10px"
                      }}
                    >
                      {/* <a href="">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/linkedin-yellow-image.png" />
                      </a> */}
                      <a href="">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/x-yellow-image.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div style={{ width: "49%" }}>
                  <div
                    style={{
                      fontSize: "27px",
                      color: "white",
                      border: "2px solid white",
                      borderRadius: "30px",
                      textAlign: "center",
                      // marginTop: "50px",
                      padding: "20px",
                      // marginBottom: "15px",
                      fontFamily: "sans-serif",
                      fontWeight: "600",
                      margin: "5px 0px",
                      marginBottom: "15px",
                    }}
                  >
                    <strong style={{ color: "#FACC08" }}>Daniel</strong> the Pilot
                    <div
                      className="mobile-socials-team-wrapper"
                      style={{
                        width: "118px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "10px"
                      }}
                    >
                      <a href="https://www.linkedin.com/in/daniel-mulyachki/">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/linkedin-yellow-image.png" />
                      </a>
                      {/* <a href="">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/x-yellow-image.png" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    fontSize: "27px",
                    color: "white",
                    border: "2px solid white",
                    borderRadius: "30px",
                    textAlign: "center",
                    // marginTop: "50px",
                    padding: "20px",
                    // marginBottom: "15px",
                    fontFamily: "sans-serif",
                    fontWeight: "600",
                    margin: "5px 0px",
                    marginBottom: "15px",
                  }}
                >
                  <strong style={{ color: "#FACC08" }}>Ani</strong> the Visionary
                  <div
                    className="mobile-socials-team-wrapper"
                    style={{
                      width: "118px",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "10px"
                    }}
                  >
                    <a href="https://www.linkedin.com/in/ani-boteva-2635632a7/?originalSubdomain=bg">
                      <img className="mobile-team-icons" style={{ width: "50px" }} src="images/linkedin-yellow-image.png" />
                    </a>
                    {/* <a href="">
                      <img className="mobile-team-icons" style={{ width: "50px" }} src="images/x-yellow-image.png" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ width: "49%" }}>
                  <div
                    style={{
                      fontSize: "27px",
                      color: "white",
                      border: "2px solid white",
                      borderRadius: "30px",
                      textAlign: "center",
                      // marginTop: "50px",
                      padding: "20px",
                      fontFamily: "sans-serif",
                      fontWeight: "600",
                      margin: "5px 0px",
                      marginBottom: "15px",
                    }}
                  >
                    <strong style={{ color: "#FACC08" }}>Miro</strong> the Code Guru
                    <div
                      className="mobile-socials-team-wrapper"
                      style={{
                        width: "118px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "10px"
                      }}
                    >
                      <a href="https://www.linkedin.com/in/miroslav-katsarov-7500ba22/">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/linkedin-yellow-image.png" />
                      </a>
                      <a href="https://x.com/miro_work">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/x-yellow-image.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div style={{ width: "49%" }}>
                  <div
                    style={{
                      fontSize: "27px",
                      color: "white",
                      border: "2px solid white",
                      borderRadius: "30px",
                      textAlign: "center",
                      // marginTop: "50px",
                      padding: "20px",
                      // marginBottom: "15px",
                      fontFamily: "sans-serif",
                      fontWeight: "600",
                      margin: "5px 0px",
                      marginBottom: "15px",
                    }}
                  >
                    <strong style={{ color: "#FACC08" }}>Trayan</strong> the Matrix
                    <div
                      className="mobile-socials-team-wrapper"
                      style={{
                        width: "118px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "10px"
                      }}
                    >
                      <a href="https://www.linkedin.com/in/tmanolov/">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/linkedin-yellow-image.png" />
                      </a>
                      <a href="https://x.com/trayanm">
                        <img className="mobile-team-icons" style={{ width: "50px" }} src="images/x-yellow-image.png" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
            <img className="meet-the-team-image" style={{ width: "630px", marginLeft: "150px" }} src="images/zexy-meet-the-team-image.png" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

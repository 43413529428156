import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";

export type HeadingPartProps = {};

type HeadingPartState = {
  here: boolean;
};

export class HeadingPart extends React.Component<HeadingPartProps, HeadingPartState> {
  state: HeadingPartState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: HeadingPartProps, prevState: HeadingPartState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div>
          <div style={{ height: "fit-content", marginBottom: "170px" }}>
            <div className="heading-part" style={{ color: "white", fontSize: "85px", marginLeft: "150px" }}>
              <p className="heading-title" style={{ minWidth: "200px", maxWidth: "720px" }}>
                THE MOST
                <img
                  className="zexy-heading-title-image"
                  style={{
                    position: "absolute",
                    maxWidth: "320px",
                    minWidth: "100px",
                    zIndex: "-1"
                  }}
                  src="images/zexy-title-image.png"
                />
                <br></br>
                MEMECOIN ON SOLANA BLOCKCHAIN!
              </p>
              <p className="zexy-subtitle" style={{ fontSize: "45px", fontFamily: "sans-serif", width: "40%", color: "rgb(229 191 82)" }}>
                {/* Designed for greatness and impact! */}
                Where we love our curves but love health more! Because calling fat 'sexy' won't fix your arteries. 😂
              </p>
              {/* <p> MEMECOIN ON SOLANA BLOCKCHAIN!</p> */}
              {/* <p> SOLANA BLOCKCHAIN!</p> */}
              {/* <button style={{
                                background: "transparent", border: "solid 1px white", borderRadius: "20px", display: "flex", alignItems: "center",
                                justifyContent: "center",
                                // padding: "18px 110px"
                            }}>
                                <p style={{ color: "white", fontSize: "37px", fontWeight: "600", margin: "16px" }}>Buy</p>
                                <img style={{ minWidth: "50px", maxWidth: "200px" }} src="images/zexy-title-image.png" />
                            </button> */}
              {/* <h2>Coming on July 15th on Raydium and Jupiter</h2> */}
              {/* <h2>Coming on July 15th on Raydium and Jupiter</h2> */}
            </div>
            <div>
              <img
                className="rocket-image"
                style={{
                  position: "absolute",
                  minWidth: "100px",
                  maxWidth: "455px",
                  right: "13%",
                  top: "340px",
                  zIndex: ""
                }}
                src="images/zexy-animated-gif.gif"
              />
              {/* <img style={{
                                position: "fixed",
                                right: "135px",
                                top: "130px",
                            }}
                                src="images/Solana_logo.png"
                            /> */}
            </div>

            <img
              className="moon-image"
              style={{
                position: "absolute",
                right: "8%",
                top: "76px",
                width: "265px"
              }}
              src="images/zexy-moon-image.png"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";

interface MemeCanvasProps {
  background: string;
  body: string;
  car: string;
  pet: string;
  other: string;
  handleRandomGeneration(): void;
}

const MemeCanvas: React.FC<MemeCanvasProps> = ({ background, body, car, pet, other, handleRandomGeneration }) => {
  const memeRef = useRef<HTMLDivElement>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const [bodyPosition, setBodyPosition] = useState({ top: 50, left: 50 });
  const [carPosition, setCarPosition] = useState({ top: -24, left: 41 });
  const [petPosition, setPetPosition] = useState({ top: -119, left: 58 });
  // const [otherPosition, setOtherPosition] = useState({ top: 70, left: 70 });

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleDownload = async () => {
    if (memeRef.current && isLoaded) {
      const canvas = await html2canvas(memeRef.current, { useCORS: true });
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `meme_${Date.now()}.png`;
      link.click();
    }
  };

  return (
    <div>
      <div
        ref={memeRef}
        style={{
          position: "relative",
          width: "500px",
          // minWidth: "350px",
          // maxWidth: "600px",
          // minHeight: "350px",
          // maxHeight: "600px",
          height: "500px",
          padding: "0px"
        }}
        className="container meme-container"
      >
        <img
          src={background}
          alt="background"
          style={{
            // position: "absolute",
            width: "100%",
            height: "100%"
          }}
          onLoad={handleImageLoad}
        />
        <img
          src={body}
          alt="body"
          style={{
            position: "absolute",
            top: `${bodyPosition.top}%`,
            left: `${bodyPosition.left}%`,
            transform: "translate(-50%, -50%)",
            width: "88%",
            marginTop: "32px",
            zIndex: "1"
          }}
        />
        <img
          src={car}
          alt="car"
          style={{
            position: "relative",
            top: `${carPosition.top}%`,
            left: `${carPosition.left}%`,
            transform: "translate(-50%, -50%)",
            width: "100%"
          }}
        />
        <img
          src={pet}
          alt="pet"
          style={{
            position: "relative",
            top: `${petPosition.top}%`,
            left: `${petPosition.left}%`,
            transform: "translate(-50%, -50%)",
            width: "46%"
          }}
        />
        {/* <img
          src={other}
          alt="other"
          style={{
            position: "absolute",
            top: `${otherPosition.top}%`,
            left: `${otherPosition.left}%`,
            transform: "translate(-50%, -50%)",
            width: "100%"
          }}
        /> */}
      </div>
      {/* <div>
        <h3>Adjust Positions</h3>
        <div>
          <label>Body Position: </label>
          <input type="range" min="0" max="100" value={bodyPosition.top} onChange={(e) => setBodyPosition({ ...bodyPosition, top: Number(e.target.value) })} />
          <input type="range" min="0" max="100" value={bodyPosition.left} onChange={(e) => setBodyPosition({ ...bodyPosition, left: Number(e.target.value) })} />
        </div>
        <div>
          <label>Hat Position: </label>
          <input type="range" min="0" max="100" value={hatPosition.top} onChange={(e) => setHatPosition({ ...hatPosition, top: Number(e.target.value) })} />
          <input type="range" min="0" max="100" value={hatPosition.left} onChange={(e) => setHatPosition({ ...hatPosition, left: Number(e.target.value) })} />
        </div>
        <div>
          <label>Other Position: </label>
          <input type="range" min="0" max="100" value={otherPosition.top} onChange={(e) => setOtherPosition({ ...otherPosition, top: Number(e.target.value) })} />
          <input type="range" min="0" max="100" value={otherPosition.left} onChange={(e) => setOtherPosition({ ...otherPosition, left: Number(e.target.value) })} />
        </div>
      </div> */}
      <div
        className="row buttons-wrapper"
        style={{
          width: "500px",
          display: "flex",
          margin: "auto",
          marginTop: "20px",
          position: "relative",
          zIndex: "1",
          justifyContent: "space-around"
        }}
      >
        <button className="col-md-5 col-sm-12 meme-button" onClick={handleDownload}>
          Download
        </button>
        <button className="col-md-5 col-sm-12 meme-button" style={{backgroundColor: "#42A5F5"}} onClick={handleRandomGeneration}>
          Random
        </button>
      </div>
    </div>
  );
};

export default MemeCanvas;

import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";

export type WhereToBuyPartProps = {};

type WhereToBuyPartState = {
  here: boolean;
};

export class WhereToBuyPart extends React.Component<WhereToBuyPartProps, WhereToBuyPartState> {
  state: WhereToBuyPartState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: WhereToBuyPartProps, prevState: WhereToBuyPartState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section id="where-to-buy container">
          {/* <div className="heading-part" style={{ color: "white", fontSize: "85px", marginLeft: "150px" }}> */}
          <div className="where-to-buy-part container" style={{ height: "fit-content", marginTop: "50px", marginBottom: "80px" }}>
            <img className="where-to-buy-title" style={{ width: "1025px", marginLeft: "150px" }} src="images/where-to-buy-title.png" />
            <div className="row">
              <div
                className="where-to-buy col-md-6"
                style={{
                  height: "600px",
                  width: "500px",
                  border: "1px solid white",
                  borderRadius: "23px",
                  margin: "auto",
                  marginTop: "75px",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#261d1d"
                }}
              >
                <h2 style={{ color: "white", fontSize: "50px" }}>
                  BUY ON <strong style={{ color: "#FACC08" }}>DEX</strong>
                </h2>
                <p style={{ color: "white", fontSize: "25px", textAlign: "center" }}>Use your Phantom wallet to connect to Raydium or Jupiter to buy $ZEXY.</p>

                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "#8e46e9",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://raydium.io/swap/?inputMint=C45EubJdjgr3FFRCZVb1yVBXxYdBjJCw1hLJoUgAXAqf&outputMint=sol", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>RAYDIUM</p>
                </button>

                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "#42aa91",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://jup.ag/swap/SOL-C45EubJdjgr3FFRCZVb1yVBXxYdBjJCw1hLJoUgAXAqf", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>JUPITER</p>
                </button>

                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "rgb(212 53 138)",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://www.orca.so/?tokenIn=So11111111111111111111111111111111111111112&tokenOut=C45EubJdjgr3FFRCZVb1yVBXxYdBjJCw1hLJoUgAXAqf", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>ORCA</p>
                </button>
              </div>
              <div
                className="where-to-buy col-md-6"
                style={{
                  height: "600px",
                  width: "500px",
                  border: "1px solid white",
                  borderRadius: "23px",
                  margin: "auto",
                  marginTop: "75px",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#261d1d"
                }}
              >
                <h2 style={{ color: "white", fontSize: "50px" }}>
                  TRACK <strong style={{ color: "#FACC08" }}>$ZEXY</strong> CHART:
                </h2>
                <p style={{ color: "white", fontSize: "25px", textAlign: "center" }}>Keep an eye on $ZEXY's growth 😏</p>
                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "rgb(212 53 138)",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://dexscreener.com/solana/2d7uesjr9xzadpvx7dguhrkugzhrcpuvr3s7ggzcim5u", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>DEX SCREENER</p>
                </button>
                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "rgb(61 114 243)",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://www.dextools.io/app/en/solana/pair-explorer/2d7UeSjR9XZadPVX7DguHRkuGZHRCPUVr3s7ggZCim5U?t=1721630371383", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>DEXTOOLS</p>
                </button>
                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "#42aa91",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://coinmarketcap.com/currencies/zexy/", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>COIN MARKET CAP</p>
                </button>
                <button
                  className="where-to-buy-button"
                  style={{
                    width: "30%",
                    height: "50px",
                    marginBottom: "15px",
                    border: "none",
                    backgroundColor: "#8e46e9",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 1px 1px #FBC02D"
                  }}
                  onClick={() => window.open("https://birdeye.so/token/C45EubJdjgr3FFRCZVb1yVBXxYdBjJCw1hLJoUgAXAqf?chain=solana&tab=recentTrades", "_blank")}
                >
                  <p style={{ color: "white", fontWeight: "400", margin: "0" }}>BIRDEYE</p>
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </React.Fragment>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppProvider";

// import images
import logo from "../assets/images/logo-light.svg";

// Props & State
export interface HeaderProps {
  // toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  search: boolean;
  navState: {
    isOpen: boolean;
  };
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state = {
    megaMenu: false,
    search: false,
    navState: {
      isOpen: false
    }
  };

  // #region State Setters
  setMegaMenu(value: boolean) {
    this.setState((prevState) => {
      return { megaMenu: value };
    });
  }

  setSearch(value: boolean) {
    this.setState((prevState) => {
      return { search: value };
    });
  }
  // #endregion

  // #region handlers
  // handleToggleLeftMenu() {
  //   const body = document.body;
  //   if (window.screen.width <= 998) {
  //     body.classList.toggle("sidebar-enable");
  //   } else {
  //     body.classList.toggle("vertical-collapsed");
  //     body.classList.toggle("sidebar-enable");
  //   }
  // }

  handleSubmitSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
  // #endregion

  closeNav = async () => {
    this.setState({
      navState: {
        isOpen: false
      }
    });
  };

  openNav = async () => {
    this.setState({
      navState: {
        isOpen: true
      }
    });
  };
  render() {
    // console.log(this.state)
    return (
      <React.Fragment>
        <header style={{ position: "sticky", top: "0px", zIndex: "4" }}>
          <div style={{ width: "100%", height: "115px" }}>
            <nav
              className="nav"
              style={{
                width: "100%",
                display: "inline-flex",
                alignContent: "center",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#000000ad"
              }}
            >
              <a href="/">
                <img className="zexy-header-logo" style={{ minWidth: "50px", maxWidth: "350px" }} src="images/zexy-logo-header.png" alt="Business Logo" />
              </a>
              <div className="header-sections" style={{ textAlign: "right" }}>
                {/* <a className="header-section" style={{
                  color: "white",
                  fontSize: "35px",
                  fontFamily: "sans-serif",
                  textDecoration: "none",
                  margin: "2px 10px"
                }} href="#whereToBuy">Where to buy</a> */}
                <a
                  className="header-section"
                  style={{
                    color: "white",
                    fontSize: "35px",
                    fontFamily: "sans-serif",
                    textDecoration: "none",
                    margin: "2px 10px"
                  }}
                  href="/#roadmap"
                >
                  Roadmap
                </a>
                <a
                  className="header-section"
                  style={{
                    color: "white",
                    fontSize: "35px",
                    fontFamily: "sans-serif",
                    textDecoration: "none",
                    margin: "2px 10px"
                  }}
                  href="/#zexynomics"
                >
                  Tokenomics
                </a>
                <a
                  className="header-section"
                  style={{
                    color: "white",
                    fontSize: "35px",
                    fontFamily: "sans-serif",
                    textDecoration: "none",
                    margin: "2px 10px"
                  }}
                  href="/#meetTheTeam"
                >
                  Team
                </a>
                <a
                  className="header-section"
                  style={{
                    color: "white",
                    fontSize: "35px",
                    fontFamily: "sans-serif",
                    textDecoration: "none",
                    margin: "2px 10px"
                  }}
                  href="/generator"
                >
                  Generator
                </a>
                <a
                  className="header-section"
                  style={{
                    color: "white",
                    fontSize: "35px",
                    fontFamily: "sans-serif",
                    textDecoration: "none",
                    margin: "2px 10px"
                  }}
                  href="/progress"
                >
                  Progress
                </a>
                {/* <a className="header-section" style={{
                  color: "white",
                  fontSize: "35px",
                  fontFamily: "sans-serif",
                  textDecoration: "none",
                  margin: "2px 20px 2px 10px"
                }} href="#section5">More</a> */}
              </div>
              {/* <div>
                <button
                  type="button"
                  onClick={() => this.openNav()}
                  
                >
                  SIDE
                </button>
                <div id="mySidenav" className={`sidenav ${this.state.navState.isOpen ? "" : "closeButton"}`}>
                  <a href="#" className="closebtn" onClick={() => this.closeNav()}>&times;</a>
                  <a href="#">About</a>
                  <a href="#">Services</a>
                  <a href="#">Clients</a>
                  <a href="#">Contact</a>
                </div>
              </div> */}
            </nav>
            <button
              className="mobile-nav-button"
              type="button"
              onClick={() => this.openNav()}
              style={{
                color: "white",
                fontSize: "30px",
                position: "absolute",
                top: "14px",
                left: "8%",
                backgroundColor: "transparent",
                border: "none"
              }}
            >
              ☰
            </button>
            <nav
              className="mobile-nav"
              style={{
                position: "sticky",
                marginTop: "-20%"
              }}
            >
              <div className={`sideNavWrapper ${this.state.navState.isOpen ? "" : "hiddenSideMenu"}`}>
                <div id="mySidenav" className={`sidenav`}>
                  <a href="#" className="closebtn" onClick={() => this.closeNav()}>
                    &times;
                  </a>
                  {/* <a href="#whereToBuy">Where to buy</a> */}
                  <a href="/#zexynomics">Tokenomics</a>
                  <a href="/#roadmap">Roadmap</a>
                  <a href="/#meetTheTeam">Team</a>
                  <a href="/generator">Generator</a>
                  <a href="/progress">Progress</a>
                  {/* <a href="#section5">More</a> */}
                </div>
              </div>
            </nav>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

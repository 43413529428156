import React, { useState } from "react";
// import './Carousel.css';

interface ElementCarrouselProps {
  items: any;
  onChange: (selectedOption: string) => void;
  currItem: string
}

const ElementCarrousel: React.FC<ElementCarrouselProps> = ({ items, onChange, currItem }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 4;
  const totalItems = items.length;

  const next = () => {
    setCurrentIndex((currentIndex + itemsToShow) % totalItems);
  };

  const prev = () => {
    setCurrentIndex((currentIndex - itemsToShow + totalItems) % totalItems);
  };

  const displayedItems = items.slice(currentIndex, currentIndex + itemsToShow);
  const additionalItems = items.slice(0, (currentIndex + itemsToShow) % totalItems);

  return (
    <div className="element-carousel">
      <button className="element-carousel-button left" style={{ color: "white" }} onClick={prev}>
        {/* &lt; */}
        <i className="fa-solid fa-circle-arrow-left"></i>
      </button>
      <div className="element-carousel-items">
        {displayedItems
          .concat(additionalItems)
          .slice(0, itemsToShow)
          .map((item: any, index: number) => (
            <div onClick={() => onChange(item)} className={`element-carousel-item ${currItem === item ? "active" : ""}`} key={index}>
              <img src={item} alt={item} />
              {/* <p>{item.name}</p> */}
            </div>
          ))}
      </div>
      <button className="element-carousel-button right" style={{ color: "white" }} onClick={next}>
        {/* &gt; */}
        <i className="fa-solid fa-circle-arrow-right"></i>
      </button>
    </div>
  );
};

export default ElementCarrousel;

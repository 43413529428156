import React from "react";
import { Layout } from "../layout";
import { Breadcrumbs } from "../layout/Breadcrumbs";
import { HeadingPart } from "../components/HeadingPart";
// import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { MeetTheTeamPart } from "../components/MeetTheTeamPart";
import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { FAQPart } from "../components/FAQPart";
import { ZexyRoadmapPart } from "../components/ZexyRoadmapPart";
import { CustomerIncentivesPart } from "../components/CustomerIncentivesPart";
import { WhereToBuyPart } from "../components/WhereToBuyPart";
import MemeGenerator from "../components/generator/MemeGenerator";

export type ZexyProgressProps = {};

type ZexyProgressState = {};

export class ZexyProgress extends React.Component<ZexyProgressProps, ZexyProgressState> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <div
            className="telegram-page-container"
            style={{
              width: "100%",
              height: "fit-content",
              textAlign: "center",
              padding: "4% 5%"
              // marginBottom: "33%"
            }}
          >
            <div className="progress-caption" style={{ width: "600px", textAlign: "left", margin: "auto", marginTop: "30px", marginBottom: "30px"}}>
              <h2 className="heading-title" style={{ color: "white", marginBottom: "20px" }}>Zexy Progress So Far:</h2>
              <p style={{ color: "white", fontSize: "20px", listStyleType: "none" }}>
                Zexy isn't just another memecoin—it's a movement towards a healthier lifestyle. Since our launch, we've made incredible strides in building a community that values
                both fun and wellness. We've successfully introduced initiatives that promote healthy living while growing our Zexy family.
              </p>

              <h2 className="heading-title" style={{ color: "white", marginBottom: "20px" }}>What’s Next:</h2>
              <p style={{ color: "white", fontSize: "20px", listStyleType: "none" }}>
                The journey is just beginning! Our future milestones include expanding our health-focused initiatives, introducing more ways for our community to earn and stay
                active, and continuing to innovate in the memecoin space. Join us as we take Zexy to new heights and make healthy living a cornerstone of our mission!
              </p>
            </div>

            <button
              style={{
                backgroundColor: "purple",
                border: "none",
                borderRadius: "10px",
                padding: "18px 36px",
                color: "white",
                fontSize: "25px",
                fontWeight: "600",
                margin: "16px"
              }}
              onClick={() => (window.location.href = "https://docs.google.com/spreadsheets/d/1kic1pJu8NIu8-SDzDxc9jZtalAg9DMSkpqVnFUSC1qo/edit?gid=0#gid=0")}
            >
              $ZEXY so far
            </button>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

import React from "react";
import { Layout } from "../layout";
import { Breadcrumbs } from "../layout/Breadcrumbs";
import { HeadingPart } from "../components/HeadingPart";
// import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { MeetTheTeamPart } from "../components/MeetTheTeamPart";
import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { FAQPart } from "../components/FAQPart";
import { ZexyRoadmapPart } from "../components/ZexyRoadmapPart";
import { CustomerIncentivesPart } from "../components/CustomerIncentivesPart";
import { WhereToBuyPart } from "../components/WhereToBuyPart";
import MemeGenerator from "../components/generator/MemeGenerator";

export type GeneratorPageProps = {};

type GeneratorPageState = {};

export class GeneratorPage extends React.Component<GeneratorPageProps, GeneratorPageState> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <div
            className="telegram-page-container"
            style={{
              width: "100%",
              height: "fit-content",
              textAlign: "center",
              padding: "4% 5%",
              // marginBottom: "33%"
            }}
          >
            <MemeGenerator />
            {/* <button
              style={{
                backgroundColor: "purple",
                border: "none",
                borderRadius: "10px",
                padding: "18px 36px",
                color: "white",
                fontSize: "25px",
                fontWeight: "600",
                margin: "16px"
              }}
              onClick={() => (window.location.href = "https://t.me/ZEXYcoin")}
            >
              Join the ZEXYEST Telegram
            </button> */}
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

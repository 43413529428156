import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Import scss
// import "../public/css";

// Pages
import { HomePage } from "./pages/HomePage";
import { TelegramPage } from "./pages/TelegramPage";
import { GeneratorPage } from "./pages/GeneratorPage";
import { ZexyProgress } from "./pages/ZexyProgress";


export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/telegram" element={<TelegramPage />} />
          <Route path="/generator" element={<GeneratorPage />} />
          <Route path="/progress" element={<ZexyProgress />} />
        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

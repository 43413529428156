import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";

export type ZexynomicsPartProps = {};

type ZexynomicsPartState = {
  here: boolean;
};

export class ZexynomicsPart extends React.Component<ZexynomicsPartProps, ZexynomicsPartState> {
  state: ZexynomicsPartState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: ZexynomicsPartProps, prevState: ZexynomicsPartState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section id="zexynomics">
          <div className="zexynomics-part" style={{ height: "fit-content" }}>
            <img className="zexynomics-title-image" style={{ width: "1025px", marginLeft: "150px" }} src="images/zexy-zexynomics-image.png" />
            <div
              className="metrics"
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                marginTop: " 80px",
                marginBottom: "80px"
              }}
            >
              <img style={{ width: "25%" }} src="images/zexy-nft-holders-image-2.png" />
              <img style={{ width: "25%" }} src="images/zexy-liquidity-pools-image-2.png" />
              <img style={{ width: "25%" }} src="images/zexy-marketing-partnership-image-2.png" />
              <img style={{ width: "25%" }} src="images/zexy-team-diagram.png" />
            </div>
            <div>
              <h2
                className="zexy-metrics-text"
                style={{
                  color: "white",
                  fontSize: "35px",
                  fontFamily: "sans-serif",
                  width: "75%",
                  margin: "auto",
                  border: "1px solid transparent",
                  backgroundColor: "#ffffff36",
                  borderRadius: "50px",
                  padding: "30px 75px",
                  fontWeight: "500"
                }}
              >
                Fat Rewards: 50% of the DEV supply distributed over time (15% of the total supply) goes to NFT holders. As a $ZEXY NFT holder, you'll enjoy chunky rewards and be a
                key player in our community.
              </h2>
            </div>
            <div>
              <h2
                className="zexy-metrics-text"
                style={{
                  color: "white",
                  fontSize: "35px",
                  fontFamily: "sans-serif",
                  width: "75%",
                  margin: "auto",
                  marginTop: "20px",
                  border: "1px solid transparent",
                  backgroundColor: "#ffffff36",
                  borderRadius: "50px",
                  padding: "30px 75px",
                  fontWeight: "500"
                }}
              >
                What you need to know about the holders drop?
                <ol style={{ marginTop: "20px" }}>
                  <li style={{ listStyle: "circle" }}>Airdrop 1: start - 2024-07-15, end - 2024-07-31</li>
                  <li style={{ listStyle: "circle" }}>Airdrop 2: start - 2024-08-01, end - 2024-08-31</li>
                  <li style={{ listStyle: "circle" }}>Airdrop 3: start - 2024-09-01, end - 2024-09-30</li>
                  <li style={{ listStyle: "circle" }}>Airdrop 4: start - 2024-10-01, end - 2024-10-31</li>
                </ol>
              </h2>
            </div>
            <div>
              <h2
                className="zexy-metrics-text"
                style={{
                  color: "white",
                  fontSize: "35px",
                  fontFamily: "sans-serif",
                  width: "75%",
                  margin: "auto",
                  marginTop: "20px",
                  border: "1px solid transparent",
                  backgroundColor: "#ffffff36",
                  borderRadius: "50px",
                  padding: "30px 75px",
                  fontWeight: "500",
                  overflowWrap: "anywhere"
                }}
              >
                <strong className="official-zexy-wallets-title" style={{ color: "#FACC08", fontSize: "45px" }}>
                  📢 Announcing the Official ZEXY Wallets! 📢
                </strong>
                <br></br>
                Track everything around the project:
                <br></br>
                <strong style={{ color: "#FACC08" }}>💼 Marketing:</strong>
                {/* Fs4nma1KKW7C4XWxE8rFTtUSAwNfZSm7zYgj5v5RcpZs */}
                6dT7eKRd6CaN9PGwzNFgjtenFtRFVbcsYoJSNjyyBxrS
                <br></br>
                <strong style={{ color: "#FACC08" }}>👥 Team:</strong>
                {/* 9PrCKWz2kSke1oguLyuYK7SHWcZqTbSJjzceL1XDUCP4 */}
                12Q3xXpfohKxRApCSxHGqjSpa4mDjMyykvoNatDygb5h
                <br></br>
                <strong style={{ color: "#FACC08" }}>🏦 Master Wallet:</strong> 4DKJqvy87Pwbaw8URQ8U2hgMKrWQdY6ZJtHBDVXmA1kd
                <p style={{ marginTop: "20px" }}>Including Upcoming CEX Tokens*</p>
              </h2>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

import React, { useState } from "react";
import MemeElementSelector from "./MemeElementSelector";
import MemeCanvas from "./MemeCanvas";
import ElementCarrousel from "./ElementCarrousel";

const backgrounds = [
  "../assets/zexy-meme-backgrounds/51.png",
  "../assets/zexy-meme-backgrounds/52.png",
  "../assets/zexy-meme-backgrounds/53.png",
  "../assets/zexy-meme-backgrounds/54.png",
  "../assets/zexy-meme-backgrounds/55.png",
  "../assets/zexy-meme-backgrounds/56.png",
  "../assets/zexy-meme-backgrounds/57.png",
  "../assets/zexy-meme-backgrounds/58.png"
];
const bodies = [
  "../assets/zexy-meme-outfits/no-background/1.png",
  "../assets/zexy-meme-outfits/no-background/2.png",
  "../assets/zexy-meme-outfits/no-background/3.png",
  "../assets/zexy-meme-outfits/no-background/4.png",
  "../assets/zexy-meme-outfits/no-background/5.png",
  "../assets/zexy-meme-outfits/no-background/6.png",
  "../assets/zexy-meme-outfits/no-background/7.png",
  "../assets/zexy-meme-outfits/no-background/8.png",
  "../assets/zexy-meme-outfits/no-background/9.png",
  "../assets/zexy-meme-outfits/no-background/10.png",
  "../assets/zexy-meme-outfits/no-background/11.png",
  "../assets/zexy-meme-outfits/no-background/12.png",
  "../assets/zexy-meme-outfits/no-background/13.png",
  "../assets/zexy-meme-outfits/no-background/14.png",
  "../assets/zexy-meme-outfits/no-background/15.png",
  "../assets/zexy-meme-outfits/no-background/16.png",
  "../assets/zexy-meme-outfits/no-background/17.png",
  "../assets/zexy-meme-outfits/no-background/18.png",
  "../assets/zexy-meme-outfits/no-background/19.png",
  "../assets/zexy-meme-outfits/no-background/20.png",
  "../assets/zexy-meme-outfits/no-background/21.png",
  "../assets/zexy-meme-outfits/no-background/22.png"
];
const cars = [
  "../assets/zexy-meme-cars/transparent-image.png",
  "../assets/zexy-meme-cars/34.png",
  "../assets/zexy-meme-cars/35.png",
  "../assets/zexy-meme-cars/36.png",
  "../assets/zexy-meme-cars/37.png",
  "../assets/zexy-meme-cars/38.png",
  "../assets/zexy-meme-cars/39.png",
  "../assets/zexy-meme-cars/40.png",
  "../assets/zexy-meme-cars/41.png",
  "../assets/zexy-meme-cars/42.png"
];

const pets = [
  "../assets/zexy-meme-cars/transparent-image.png",
  "../assets/zexy-meme-pets/43.png",
  "../assets/zexy-meme-pets/44.png",
  "../assets/zexy-meme-pets/45.png",
  "../assets/zexy-meme-pets/46.png",
  "../assets/zexy-meme-pets/47.png",
  "../assets/zexy-meme-pets/48.png",
  "../assets/zexy-meme-pets/49.png",
  "../assets/zexy-meme-pets/50.png"
];

const MemeGenerator: React.FC = () => {
  const [background, setBackground] = useState<string>(backgrounds[Math.floor(Math.random() * backgrounds.length)]);
  const [body, setBody] = useState<string>(bodies[Math.floor(Math.random() * bodies.length)]);
  const [car, setCar] = useState<string>(cars[Math.floor(Math.random() * cars.length)]);
  const [pet, setPet] = useState<string>(pets[Math.floor(Math.random() * pets.length)]);
  const [other, setOther] = useState<string>("");

  const others = ["", "https://picsum.photos/id/401/50", "https://picsum.photos/id/402/50", "https://picsum.photos/id/403/50"];

  const handleRandomGeneration = () => {
    setBackground(backgrounds[Math.floor(Math.random() * backgrounds.length)]);

    setBody(bodies[Math.floor(Math.random() * bodies.length)]);

    setCar(cars[Math.floor(Math.random() * cars.length)]);

    setPet(pets[Math.floor(Math.random() * pets.length)]);

    setOther(others[Math.floor(Math.random() * others.length)]);
  };

  // const importAll = (r: __WebpackModuleApi.RequireContext) => {
  //   let images: { [key: string]: string } = {};
  //   r.keys().forEach((item: any) => {
  //     images[item.replace('./', '')] = r(item).default;
  //   });
  //   return images;
  // }

  // const importedImages = importAll(require.context('.../assets', false, /.(png)/));
  // console.log(importedImages)
  // // Importing images
  // const images = importAll(require.context('../public/assets', false, /.(png)/));

  return (
    <div className="container" style={{ maxWidth: "1380px" }}>
      <h1
        className="heading-title mobile-meme-heading"
        style={{
          color: "white",
          fontSize: "85px",
          width: "80%",
          margin: "auto",
          marginBottom: "150px"
        }}
      >
        CREATE THE FATTEST{" "}
        <strong className="outlined-zexy" style={{ color: "black" }}>
          $ZEXY
        </strong>{" "}
        MEME
      </h1>
      <div
        className="row"
        style={{
          height: "fit-content",
          marginBottom: "24%"
        }}
      >
        <div className="col-md-6">
          <MemeCanvas background={background} body={body} car={car} other={other} pet={pet} handleRandomGeneration={handleRandomGeneration} />
        </div>
        <div className="col-md-6 carrousels-wrapper" style={{ padding: "0px", zIndex: "2" }}>
          {/* <MemeElementSelector label="Background" options={backgrounds} onChange={setBackground} />
          <MemeElementSelector label="Body" options={bodies} onChange={setBody} />
          <MemeElementSelector label="Car" options={cars} onChange={setCar} />
          <MemeElementSelector label="Pet" options={pets} onChange={setPet} />
          <MemeElementSelector label="Other" options={others} onChange={setOther} /> */}
          <div>
            <ElementCarrousel items={bodies} currItem={body} onChange={setBody} />
          </div>
          <div>
            <ElementCarrousel items={cars} currItem={car} onChange={setCar} />
          </div>
          <div>
            <ElementCarrousel items={backgrounds} currItem={background} onChange={setBackground} />
          </div>
          <div>
            <ElementCarrousel items={pets} currItem={pet} onChange={setPet} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemeGenerator;

import React from "react";
import { Layout } from "../layout";
import { Breadcrumbs } from "../layout/Breadcrumbs";
import { HeadingPart } from "../components/HeadingPart";
// import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { MeetTheTeamPart } from "../components/MeetTheTeamPart";
import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { FAQPart } from "../components/FAQPart";
import { ZexyRoadmapPart } from "../components/ZexyRoadmapPart";
import { CustomerIncentivesPart } from "../components/CustomerIncentivesPart";
import { WhereToBuyPart } from "../components/WhereToBuyPart";

const REASONS = [
  {
    title: "Exclusive Updates",
    reason: "Receive the latest news, updates, and announcements about ZEXY directly in your Telegram feed."
  },
  {
    title: "Community Support",
    reason: "Connect with like-minded individuals and get support from the community and the ZEXY team."
  },
  {
    title: "Networking Opportunities",
    reason: "Meet and network with other members of the ZEXY ecosystem, including developers, investors, and enthusiasts."
  },
  {
    title: "Early Access",
    reason: "Be the first to know about and access new features, beta tests, and other opportunities within the ZEXY platform."
  },
  {
    title: "Community Events",
    reason: "Get invites to community events, webinars, and exclusive meetups."
  },
  {
    title: "Contests and Giveaways",
    reason: "Participate in contests, giveaways, and other interactive activities that are exclusive to the Telegram chat members."
  }
]



export type TelegramPageProps = {};

type TelegramPageState = {};

export class TelegramPage extends React.Component<TelegramPageProps, TelegramPageState> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <div 
          className="telegram-page-container"
          style={{
            width: "100%",
            height: "100vh",
            textAlign: "center", padding: "4% 5%"
          }}>
            <div className="telegram-page-foods">
              <img className="zexy-food-icon" src="images/zexy-food-icon-1.png" />
              <img
                style={{
                  left: " 90%",
                  marginTop: "-55px"
                }}
                className="zexy-food-icon" src="images/zexy-food-icon-2.png"
              />
              <img
                style={{
                  left: "77%",
                  marginTop: "3%"
                }}
                className="zexy-food-icon" src="images/zexy-food-icon-3.png" />
              <img
                style={{
                  left: "79%",
                  marginTop: "20%"
                }}
                className="zexy-food-icon" src="images/zexy-food-icon-4.png"
              />
              <img
                style={{
                  marginTop: "20%",
                  left: "11%"
                }}
                className="zexy-food-icon" src="images/zexy-food-icon-5.png" />
              <img style={{
                left: "5%",
                marginTop: "41%"
              }} className="zexy-food-icon" src="images/zexy-food-icon-6.png" />
              <img
                style={{
                  left: " 23%",
                  marginTop: "10%"
                }}
                className="zexy-food-icon" src="images/zexy-food-icon-7.png"
              />
              <img
                style={{
                  left: "21%",
                  marginTop: "34%"
                }}
                className="zexy-food-icon" src="images/zexy-food-icon-8.png" />
              <img style={{
                left: "74%",
                marginTop: "33%"
              }}
                className="zexy-food-icon" src="images/zexy-food-icon-9.png" />
              <img style={{
                left: "87%",
                marginTop: "27%"
              }} className="zexy-food-icon" src="images/zexy-food-icon-10.png" />



            </div>
            <h1 className="heading-title" style={{ color: "white", marginBottom: "20px" }}>JOIN OUR TELEGRAM!</h1>
            <ul style={{ width: "600px", textAlign: "left", margin: "auto", marginTop: "30px", marginBottom: "30px" }}>
              {REASONS.map((reason) =>
                <li style={{ color: "white", fontSize: "20px", listStyleType: "none" }}> <strong>{reason.title}</strong>: {reason.reason}</li>
              )}
            </ul>
            <button style={{
              backgroundColor: "purple",
              border: "none",
              borderRadius: "10px",
              padding: "18px 36px",
              color: "white",
              fontSize: "25px",
              fontWeight: "600",
              margin: "16px"
            }}
              onClick={() => window.location.href = "https://t.me/ZEXYcoin"}
            >
              Join the ZEXYEST Telegram
            </button>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";
import { flatten } from "lodash";

export type FAQPartProps = {};

type FAQPartState = {
  FAQ: Array<any>;
};

// # FAQ Page for $ZEXY

// ## What is $ZEXY?

// $ZEXY is a meme token created by the ZoneX team, known for their successful NFT collection. It aims to push the boundaries of digital currency through innovative community initiatives and engaging features.

// ## What are the key features of $ZEXY?

// - NFT Rewards: 50% of the DEV supply (15% of total supply) is distributed over time to NFT holders.
// - Liquidity Pools: 70% of the supply over time to ensure smooth trading.
// - Twitter Engagement: 5% of the supply over time for active Twitter users.
// - Marketing & Partnerships: 10% of the supply over time to promote $ZEXY and form strong alliances.

// ## Where can I buy $ZEXY?

// $ZEXY is available on various decentralized exchanges (DEXes). Detailed instructions and links will be provided on our "Where to Buy" page.

// ## How can I engage with the $ZEXY community?

// Join our community initiatives, participate in Twitter campaigns, and engage with us through various interactive features like the meme generator.

export class FAQPart extends React.Component<FAQPartProps, FAQPartState> {
  state: FAQPartState = {
    FAQ: [
      {
        id: 1,
        title: "What is $ZEXY?",
        answer:
          "$ZEXY is a meme token created by the ZoneX team, known for their successful NFT collection. It aims to push the boundaries of digital currency through innovative community initiatives and engaging features.",
        isOpen: false
      },
      {
        id: 2,
        title: "What are the key features of $ZEXY?",
        answer: `NFT Rewards: 50% of the DEV supply (15% of total supply) is distributed over time to NFT holders. 
                - Liquidity Pools: 70% of the supply over time to ensure smooth trading. - Twitter Engagement: 5% of the supply over time for active Twitter users. 
                - Marketing & Partnerships: 10% of the supply over time to promote $ZEXY and form strong alliances.`,
        isOpen: false
      },
      {
        id: 3,
        title: "Where can I buy $ZEXY?",
        answer: `$ZEXY is available on various decentralized exchanges (DEXes). Detailed instructions and links will be provided on our "Where to Buy" page.`,
        isOpen: false
      },
      {
        id: 4,
        title: "How can I engage with the $ZEXY community?",
        answer: "Join our community initiatives, participate in Twitter campaigns, and engage with us through various interactive features like the meme generator.",
        isOpen: false
      },
      {
        id: 5,
        title: "What is the $ZEXY adress?",
        answer: "The adress of the token is: C45EubJdjgr3FFRCZVb1yVBXxYdBjJCw1hLJoUgAXAqf",
        isOpen: false
      }
    ]
  };

  componentDidMount = async () => {
    // setup connection
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: FAQPartProps, prevState: FAQPartState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };

  onQuestionToggleClick = (questionIndex: number) => {
    // const currentQuestion = this.state.FAQ.filter((question, index) => question[index] === this.state.FAQ[questionIndex])
    this.setState({
      FAQ: this.state.FAQ.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            isOpen: !question.isOpen
          };
        } else {
          return question;
        }
      })
    });
  };

  onTextToLogoChange = (title: string) => {
    if (title.includes("ZEXY")) {
      const newTitle = title.replace("ZEXY", `<img src="images/zexy-title-image.png"/>`);
      return newTitle;
    }
  };

  handleOpenPdf = () => {
    // Replace 'sample.pdf' with the path to your PDF file
    const pdfPath = process.env.PUBLIC_URL + "/ZEXY_DECK.pdf";
    window.open(pdfPath, "_blank");
  };

  render() {
    // console.log(this.state)
    return (
      <React.Fragment>
        <div>
          <div style={{ height: "fit-content", width: "80%", margin: "auto", textAlign: "center", marginBottom: "60px" }}>
            {/* <img style={{ width: "1025px", marginLeft: "150px" }} src="images/zexy-FAQ-title-image.png" /> */}
            <p className="FAQ-part-title" style={{ fontSize: "80px", color: "white", fontFamily: "fantasy" }}>
              THE WHAT/ WHY/ HOW/ WHEN
            </p>
            <img className="FAQ-part-title-image" style={{ width: "800px" }} src="images/zexy-FAQ-title-image.png" />
          </div>
          <div>
            {this.state.FAQ.map((question, index) => {
              // const title = this.onTextToLogoChange(question.title)
              // console.log(question)
              return (
                <div
                  key={question.id}
                  className={`faq-question ${this.state.FAQ[index].isOpen ? "active-question" : ""}`}
                  style={
                    {
                      // height: "90px",
                      // width: "60%",
                      // border: "1px solid white",
                      // borderRadius: "20px",
                      // margin: "auto",
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-around",
                      // marginBottom: "10px"
                    }
                  }
                >
                  <div
                    style={{
                      // display: "flex",
                      // width: "100%",
                      // justifyContent: "center",
                      // alignItems: "center",
                      // alignSelf: "flex-start",
                      // marginTop: "13px",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "flex-start",
                      height: "100%",
                      margin: "auto"
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontSize: "30px",
                        fontFamily: "sans-serif",
                        width: "80%",
                        margin: "0px",
                        alignSelf: "flex-start",
                        marginTop: "20px"
                      }}
                    >
                      {question.title}
                    </p>
                    <button
                      onClick={() => this.onQuestionToggleClick(index)}
                      style={{
                        color: "white",
                        backgroundColor: "#facc08",
                        border: "none",
                        borderRadius: "10px",
                        height: "65px",
                        width: "65px",
                        fontSize: "40px",
                        alignSelf: "baseline",
                        marginTop: "10px"
                      }}
                    >
                      <p> + </p>
                    </button>
                  </div>
                  <div className="faq-answer">
                    <p
                      className={`${!this.state.FAQ[index].isOpen ? "hidden-answer" : ""}`}
                      style={{
                        color: "white",
                        padding: "0px 60px",
                        marginTop: "29px"
                      }}
                    >
                      {question.answer}
                    </p>
                  </div>
                </div>
              );
            })}
            {/* <div className="faq-question" style={{
                            height: "90px",
                            width: "60%",
                            border: "1px solid white",
                            borderRadius: "20px",
                            margin: "auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around"
                        }}
                        >
                            <p  style={{color: "white", fontSize: "30px", fontFamily:"sans-serif", width: "80%"}}>How am i so cool?</p>
                            <button style={{backgroundColor: "yellow", border: "none", borderRadius: "10px", height: "85%", width: "5%"}}> + </button>
                        </div> */}
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button className="more-info-button" 
            style={{ 
                backgroundColor: "transparent", 
                border: "none",
                marginTop: "35px"
                }} 
                onClick={this.handleOpenPdf}>
              <a style={{ color: "white" }}>MORE $ZEXY INFO</a>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

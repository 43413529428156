import React from "react";
import { Layout } from "../layout";
import { Breadcrumbs } from "../layout/Breadcrumbs";
import { HeadingPart } from "../components/HeadingPart";
// import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { MeetTheTeamPart } from "../components/MeetTheTeamPart";
import { ZexynomicsPart } from "../components/ZexynomicsPart";
import { FAQPart } from "../components/FAQPart";
import { ZexyRoadmapPart } from "../components/ZexyRoadmapPart";
import { CustomerIncentivesPart } from "../components/CustomerIncentivesPart";
import { WhereToBuyPart } from "../components/WhereToBuyPart";

export type HomePageProps = {};

type HomePageState = {};

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <HeadingPart />
          <WhereToBuyPart />
          <ZexynomicsPart />
          {/* <MeetTheTeamPart /> */}
          <ZexyRoadmapPart />
          <CustomerIncentivesPart />

          <MeetTheTeamPart />
          <FAQPart />
        </Layout>
      </React.Fragment>
    );
  }
}

import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";


export type CustomerIncentivesPartProps = {

};

type CustomerIncentivesPartState = {
    here: boolean;
};

export class CustomerIncentivesPart extends React.Component<CustomerIncentivesPartProps, CustomerIncentivesPartState> {
    state: CustomerIncentivesPartState = {
        here: true
    };

    // #region React Events
    componentDidMount = async () => {
        // setup connection

        // await this.setState((prevState) => {
        //   return { here: true };
        // });
    };

    componentDidUpdate = async (prevProps: CustomerIncentivesPartProps, prevState: CustomerIncentivesPartState) => {
        // execute
    };

    componentWillUnmount = async () => {
        // destroy connection
    };
    // #endregion

    // #region Handlers
    // #endregion

    // #region State Setters
    // #endregion

    // #region Functions
    // #endregion

    render() {
        return (
            <React.Fragment>
                <section id="zexynomics">
                    {/* <div className="heading-part" style={{ color: "white", fontSize: "85px", marginLeft: "150px" }}> */}
                    <div className="zexynomics-part" style={{ height: "fit-content", marginTop: "50px" }}>
                        <img className="zexynomics-title-image" style={{ width: "1025px", marginLeft: "150px" }} src="images/customer-incentives-title-image.png" />
                        {/* <p className="heading-title" style={{ minWidth: "200px", fontSize: "50px", color: "white", marginLeft: "165px" }}>THE MOST

                            MEMECOIN ON SOLANA BLOCKCHAIN!
                        </p> */}
                        <div className="tesla-graph" style={{ width: "50%", margin: "auto", marginTop: "80px" }}>
                            <img style={{ width: "100%" }} src="images/tesla-graph-image-3.png" />
                        </div>
                        <div className="tesla-progress-levels" style={{ width: "50%", margin: "auto", marginTop: "50px"}}>
                            <img style={{ width: "100%" }} src="images/tesla-progress-levels-image-2.png" />
                        </div>

                    </div>
                    {/* </div> */}

                </section>
            </React.Fragment>
        );
    }
}
import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

type LayoutProps = {
  children: any;
};

type LayoutState = {};

export class Layout extends React.Component<LayoutProps, LayoutState> {
  private isMobile: boolean;
  private leftSideBarTheme: string = "dark";
  private leftSideBarType: string = "default";

  constructor(props: LayoutProps) {
    super(props);
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  //#region 
  toggleMenuCallback() {
    // TODO : Implement me
  }
  // #endregion

  // #region React Events
  componentDidMount() { }
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div>
          <Header />

          <div>{this.props.children}</div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

import React from "react";

// Props & State
export type FooterProps = {};

type FooterState = {};

export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <footer className="">
          <div className="container">
            <div className="row" style={{ paddingTop: "50px" }}>
              {/* <div style={{ color: "white" }} className="col-md-4"> <h3>info@zexy.meme</h3></div> */}
              <div className="col-md-6" style={{
                display: "flex",
                flexDirection: "column"
              }}
              >
                <div style={{
                  margin: "auto", display: "flex",
                  flexDirection: "column",
                  marginBottom: "25px"
                }}>
                  <h3 style={{ color: "white", marginBottom: "20px" }}> <strong style={{ color: "#FACC08" }}>QUICK LINKS</strong></h3>
                  <a href="#zexynomics" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}>Tokenomics</a>
                  {/* <a href="#" style={{color: "white", fontWeight: "500"}}>Cominity initiatives</a>   */}
                  <a href="#meetTheTeam" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}>Team</a>
                  {/* <a href="#" style={{color: "white", fontWeight: "500"}}>Customer Insentives</a>   */}
                  <a href="#roadmap" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}>Roadmap</a>
                  <a href="#" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}>Where to buy?</a>
                  {/* <a href="#" style={{color: "white", fontWeight: "500"}}>Calculator</a>   */}
                  <a href="#" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}>FAQ</a>
                  {/* <a href="#" style={{color: "white", fontWeight: "500"}}>Meme Generator</a>   */}
                </div>
              </div>
              <div className="col-md-6" style={{
                display: "flex",
                flexDirection: "column"
              }}>
                <div style={{
                  margin: "auto", display: "flex",
                  flexDirection: "column", marginBottom: "25px"
                }}>
                  <h3 style={{ color: "white", marginBottom: "20px" }}> <strong style={{ color: "#FACC08" }}>SOCIAL MEDIA</strong></h3>
                  <a href="https://x.com/ZEXY_MEME" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}> <img style={{ width: "48px" }} src="images/social-x.png" /></a>
                  <a href="/telegram" style={{ color: "white", fontWeight: "500", textDecoration: "none" }}> <img style={{ width: "48px" }} src="images/social-telegram.png" /></a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
